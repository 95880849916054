export const HOST_PORT = `${process.env.REACT_APP_HOST_BASE_URL}:${process.env.REACT_APP_HOST_BASE_PORT}/`;

export const API_BASE_URL = HOST_PORT + "api";

export const DEFAULT_IMG = "img/user.png";

export const PER_PAGE = 25;

export const PERMISSIONS = {
	DASHBOARD: "dashboard",
	USER: "user",
	PERMISSION: "permission",
	STAFF: "staff",
	BANK: "bank",
	CLIENT: "client",
	PRODUCT: "product",
	WORK_TYPE: "work_type",
	JOB_WORK: "job_work",
	RETURN: "return",
	ASSIGN: "assign",
	DELIVERY: "delivery",
	PHYSICAL_DELIVERY: "physical_delivery",
	PAYMENT: "payment",

	DELETED_JOB_WORK: "deleted_job_work",
	VIEW_JOB_WORK_AMOUNT: "view_job_work_amount",
	VIEW_CLIENT_AMOUNT: "view_client_amount",
	SUMMARY: "summary",
	VIEW_COLLECTOR_DETAILS: "view_collector_details",

	DOWNLOAD_INVOICE: "download_invoice",
};

export const ACTION = {
	VIEW: "view",
	CREATE: "create",
	EDIT: "edit",
	DELETE: "delete",
};

export const payment_method = [
	{ label: "Bank", value: "bank" },
	{ label: "Upi", value: "upi" },
	{ label: "Cash", value: "cash" },
];

export const job_type = [
	{ label: "Video", value: "video_shoot" },
	{ label: "Photo", value: "photo_shoot" },
];

export const job_work_status = [
	{ label: "Pending", value: "pending" },
	{ label: "Processing", value: "processing" },
	{ label: "Completed", value: "completed" },
	{ label: "Partial Delivered", value: "partial_delivered" },
	{ label: "Delivered", value: "delivered" },
];

export const user_type = [
	{ label: "User", value: "user" },
	{ label: "Client", value: "client" },
];

export const E_JOB_WORK_STATUS = {
	pending: "pending",
	processing: "processing",
	completed: "completed",
	partial_delivered: "partial_delivered",
	delivered: "delivered",
};

export const E_USER_TYPE = {
	admin: "admin",
	user: "user",
	client: "client",
};
